// This is a simple and maybe poor optmized phone validation function
// We might want to consider to implement a specific library in future in order to replace this
export const isValidPhoneNumber = (value: string) => {
  if (!value) {
    return false
  }

  // setup
  const firstChar = value.charAt(0)
  const onlyNumbersRegex = /^\d+$/
  const onlyNumbersAndSpacesRegex = /^(?=.*\d)[\d ]+$/
  const minLenght = 4
  const maxLengh = 20
  const valueWithoutWhiteSpaces = value.replace(/ /g, '')

  // checking conditions
  const hasValidFirstCharacter =
    firstChar === '+' || onlyNumbersRegex.test(firstChar)
  const hasOnlyNumbersAndSpaces = onlyNumbersAndSpacesRegex.test(
    value.substr(1)
  )
  const isCorrectLenght =
    valueWithoutWhiteSpaces.length > minLenght &&
    valueWithoutWhiteSpaces.length <= maxLengh

  // we return true if all conditions are satified
  return hasValidFirstCharacter && hasOnlyNumbersAndSpaces && isCorrectLenght
}
