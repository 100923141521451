export const defaultTheme = {
  brand: {
    primary: {
      background: '#fd9645',
      color: '#fff',
      boxShadow: '1px 3px 6px 0 rgba(253, 150, 69, 0.3)',
    },
  },
  input: {
    default: {
      placeholder: '#808080',
      backgroundColor: '#fff',
      borderColor: 'rgba(34, 36, 38, 0.15)',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    disabled: {
      background: 'rgba(34, 36, 38, 0.03)',
      color: 'rgb(128 128 128)',
    },
    error: {
      backgroundColor: '#fef5f4',
      borderColor: '#cc2029',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      backgroundColor: 'rgba(46, 204, 113, 0.1)',
      borderColor: '#0c783a',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  button: {
    facebook: {
      backgroundColor: '#3778ea',
      backgroundColorHover: '#2f6edd',
      color: '#fff',
    },
    tiktok: {
      backgroundColor: '#fe2c55',
      color: '#fff',
    },
    primary: {
      backgroundColor: '#22a7f0',
      backgroundColorHover: '#2987cd',
      color: '#fff',
    },
    secondary: {
      backgroundColor: '#121232',
      backgroundColorHover: '#28304a',
      color: '#fff',
    },
    link: {
      backgroundColor: 'none',
      color: '#3778EA',
    },
  },
  container: {
    medium: '768px',
    large: '1080px',
    extralarge: '1280px',
  },
  font: {
    family: '"Open Sans", sans-serif',
  },
  color: {
    primary: '#41a6dc',
    action: '#fd9645',
    link: '#4183c4',
    green: '#2ecc71',
    error: '#cc2029',
  },
  zIndex: {
    modal: 1000,
    panelFooter: 50,
    dropdown: 60,
    overallNotifications: 9999, // for toasts and alerts
  },
  utils: {
    boxShadow:
      'rgb(0 0 0 / 2%) 0px -4px 6px -1px, rgb(0 0 0 / 4%) 0px -2px 4px -1px',
  },
}

export type CustomDefaultTheme = typeof defaultTheme
