import React, { Dispatch, forwardRef, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Header,
  Label,
  List,
  Loader,
  Message,
  Radio,
} from 'semantic-ui-react'
import { FbAppStoreStep } from 'LeadsBridgeApp'
import { FbAppStoreStepper } from '../../../utils/appStore/FbAppStoreStepper'
import FbAppStoreService from '../../../services/FbAppStoreService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import { sendToast } from '../../../store/actions/ApplicationConfigurationActions'
import { doPostMessage, Messages } from '../../../utils/PostMessages'

const PlatformConfiguration = forwardRef(
  (props: { steps: FbAppStoreStep[] }, ref) => {
    const dispatch: Dispatch<any> = useDispatch()
    const platformId: number = useSelector(
      (state: RootState) => state.appStore.platformId
    )
    const [type, setType] = useState<string>('default')
    const [platformName, setPlatformName] = useState('')
    const [entities, setEntities] = useState<any[]>([])
    const [checkedEntity, setCheckedEntity] = useState<string>('')
    const [checkedEntities, setCheckedEntities] = useState<any[]>([])

    const [privacy1, setPrivacy1] = useState<boolean>(false)
    const [privacy2, setPrivacy2] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [loadingEntities, setLoadingEntities] = useState<boolean>(true)

    useEffect(() => {
      if (FbAppStoreStepper.googleSheet === props.steps) {
        setType('google')
      }
      // do post getPlatformCapabilities passandogli come parametro platformId
    }, [])

    useEffect(() => {
      if (platformId > 0) {
        FbAppStoreService.getPlatformCapabilities(platformId)
          .then((res) => {
            setPlatformName(res.PlatformName || 'source')
            setEntities(res.Entities)
          })
          .finally(() => setLoadingEntities(false))
      }
    }, [platformId])

    const entityCheck = (value: any) => {
      setCheckedEntity(value)
    }

    const saveConfiguration = () => {
      const parsedEntities: string[] = (
        type === 'google' ? [checkedEntity] : checkedEntities
      ).filter(function (el) {
        return el != null && el !== ''
      })
      if (parsedEntities.length === 0) {
        dispatch(
          sendToast({
            title: 'Warning',
            messages: ['Fields are required'],
            color: 'warning',
          })
        )
        return
      }

      setSaving(true)
      doPostMessage(Messages.PlatformCompleted)
      FbAppStoreService.doPublishBridges(
        platformId,
        parsedEntities,
        privacy1,
        privacy2
      )
        .then((res) => {})
        .catch((e) => {
          const error =
            e.response?.data?.errorMessage ??
            'An internal error occurred, please try again later.'
          dispatch(
            sendToast({
              title: 'An error occurred',
              messages: [error],
              color: 'negative',
            })
          )
        })
        .finally(() => {
          setSaving(false)
        })
    }

    return (
      <div>
        <Header as={'h1'}>Review configuration</Header>

        <Dimmer
          inverted
          active={platformId === 0 || loadingEntities}
          style={{
            marginTop: '' + '' + '50px',
          }}
        >
          <Loader>Loading</Loader>
        </Dimmer>

        <p>
          {type === 'google' ? 'Choose ' : 'Select '} the objects to sync and
          check below some examples of the data that will be synced.
          <br />
          Continuing to the next step you authorize LeadsBridge sends these data
          to Facebook.
        </p>
        <List divided relaxed>
          {Object.keys(entities).map((key: any, index: number) => {
            return (
              <List.Item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '1em',
                }}
                onClick={(e) => {
                  if (type === 'google') {
                    entityCheck(key)
                  } else {
                    const newCheckedEntities = [...checkedEntities]
                    const keyIndex = newCheckedEntities.indexOf(key)
                    if (keyIndex >= 0) {
                      newCheckedEntities.splice(keyIndex, 1)
                    } else {
                      newCheckedEntities.push(key)
                    }
                    setCheckedEntities(newCheckedEntities)
                  }
                }}
                key={'entity-' + index}
              >
                {type === 'google' ? (
                  <Radio checked={checkedEntity === key} />
                ) : (
                  <Checkbox checked={checkedEntities.indexOf(key) >= 0} />
                )}
                <List.Content style={{ marginLeft: '15px' }}>
                  <List.Description as='a'>
                    {entities[key].Label}
                  </List.Description>
                  <List.Description>
                    {Object.keys(entities[key].SourceMappedFields).map(
                      (k: any) => {
                        return (
                          <Label style={{ marginBottom: '3px' }}>
                            {entities[key].SourceMappedFields[k]}
                          </Label>
                        )
                      }
                    )}
                  </List.Description>
                </List.Content>
              </List.Item>
            )
          })}
        </List>

        <Message style={{ marginTop: '35px' }}>
          <Message.Header>Changes in Service</Message.Header>
          <p>
            Decide what Facebook can do automatically with data from{' '}
            {platformName}
          </p>
          <Form>
            <Form.Field>
              <Form.Checkbox
                label={'Ads Delivery Optimization'}
                onClick={() => setPrivacy1(!privacy1)}
                checked={privacy1}
              />
              <p
                style={{
                  marginLeft: '1.85714em',
                  marginTop: '-10px',
                  marginBottom: '10px',
                  opacity: 0.6,
                }}
              >
                Allow Facebook to make subtle adjustements to your campaing to
                maximize performance.
              </p>
            </Form.Field>
            <Form.Field style={{ marginTop: '25px' }}>
              <Form.Checkbox
                label={'Limited Data Use'}
                onClick={() => setPrivacy2(!privacy2)}
                checked={privacy2}
              />
              <p
                style={{
                  marginLeft: '1.85714em',
                  marginTop: '-10px',
                  opacity: 0.6,
                }}
              >
                {' '}
                Communicate when customer in California have opted out of the
                sale of data as defined by the CCPA.
              </p>
            </Form.Field>
          </Form>
        </Message>

        <div style={{ textAlign: 'right' }}>
          <Button
            color={'blue'}
            className={saving ? 'loading disabled' : ''}
            disabled={saving}
            onClick={() => saveConfiguration()}
          >
            Start sync
          </Button>
        </div>
      </div>
    )
  }
)

export default PlatformConfiguration
