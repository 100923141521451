import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios'
import { Auth } from 'aws-amplify'
import Cookies from 'universal-cookie'
import { PersistentCookiesKeys } from '@app/enums/persistentCookiesKeys'

const interceptor = axios.create({
  baseURL: process.env.REACT_APP_V1_API,
  timeout: 60000,
})

export const axiosInterceptor = async (): Promise<AxiosInstance> => {
  const { accessToken, expiration } = await Auth.currentSession().then(
    (session) => {
      const expiration = session.getIdToken().getExpiration()
      const accessToken = session.getAccessToken()
      const jwt = accessToken.getJwtToken()
      return { accessToken: jwt, expiration: expiration }
    }
  )

  const currentTimeSeconds = Math.round(+new Date() / 1000)
  if (expiration < currentTimeSeconds) {
    // console.log('token expired TODO REFRESH');
  }

  const exp: string | null = localStorage.getItem('auth-expiration')
  if (exp) {
    const date = new Date()
    const date2 = Date.parse(exp)
    if (date.getTime() >= date2) {
      localStorage.clear()
      await Auth.signOut()
      return Promise.reject('Session Expired')
    }
  }

  const user = await Auth.currentAuthenticatedUser().then((user) => {
    return user
  })
  await user.getCachedDeviceKeyAndPassword()

  interceptor.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders
      }
      config.headers.Authorization = `Bearer ${accessToken}`
      config.headers['Content-Type'] = 'application/json'

      // user impersonification (SB-522)
      const cookies = new Cookies()
      const impValue = cookies.get(PersistentCookiesKeys.LbImp)
      if (impValue) {
        config.headers['X-logged-as-Id'] = impValue
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return interceptor
}
