import { axiosInterceptor } from './axiosInterceptor'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { FacebookParameters } from 'LeadsBridgeApp'

export default class LoginV1Service {
  public static authorizeUrl: string = '/auth/v2/initUser'
  public static handleFbeParametersUrl: string = '/fbe/v2/handleFbeParameters'
  public static setupInstanceUrl: string = '/fbe/v2/setupInstance'
  public static fbeCancelUrl: string = '/fbe/v2/fbeCancel'
  public static evoToken: string = '/auth/v2/evoToken'

  static authorize = async (): Promise<{}> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(process.env.REACT_APP_V1_API + LoginV1Service.authorizeUrl, {})
        .then((res: AxiosResponse) => {
          return res.data
        })
        .catch((error) => {
          return null
        })
    })
  }

  static facebookParameters = async (
    flowType: string,
    instanceId: string,
    url: string
  ): Promise<FacebookParameters> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(
          process.env.REACT_APP_V1_API + LoginV1Service.handleFbeParametersUrl,
          {
            flowType: flowType,
            instanceId: instanceId,
            requestUri: url,
          }
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  // test deploy
  static setupInstance = async (
    data: Record<string, unknown>
  ): Promise<{ callbackPath: string }> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(
          process.env.REACT_APP_V1_API + LoginV1Service.setupInstanceUrl,
          data
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static fbeCancel = async (data: object): Promise<{}> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(process.env.REACT_APP_V1_API + LoginV1Service.fbeCancelUrl, data)
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static setupSessionCookie = async (
    jwt: string,
    domain: string
  ): Promise<{ callbackPath: string }> => {
    return await axios
      .post(
        process.env.REACT_APP_V1_API + LoginV1Service.evoToken,
        {
          token: jwt,
          domain: domain,
        },
        { withCredentials: true }
      )
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return null
      })
  }

  static cancelSessionCookie = async (
    domain: string
  ): Promise<{ callbackPath: string }> => {
    return await axios
      .delete(process.env.REACT_APP_V1_API + LoginV1Service.evoToken, {
        data: { domain: domain },
        withCredentials: true,
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return null
      })
  }
}
