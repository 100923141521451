import React, { useEffect, useState } from 'react'
import { IncomingDataFinalStep } from '@features/nbee/IncomingDataContent/IncomingDataFinalStep'
import { useUpdateIntegration } from '@app/api/updateIntegration'
import { useNavigate, useParams } from 'react-router-dom'
import { IncomingDataParams } from 'LeadsBridgeApp'
import { Loader } from '@app/components/Basic/Loader'
import { parseApiError } from '@app/api/utils/error'
import { useAppDispatch } from '@app/store/hooks'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { appRoutes } from '@app/routes'
import { useGetIntegrationById } from '@app/api/getIntegrationById'
import { sendIntegrationDataToParentWindow } from '@app/features/nbee/IntegrationCredentialsContent/utils'

export const IncomingDataScreenSave: React.FC = () => {
  const { appId, integrationId } = useParams<IncomingDataParams>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    data: integrationData,
    isLoading: isFetchingIntegrationData,
    error: integrationDataApiError,
  } = useGetIntegrationById(integrationId)

  const {
    mutate: updateIntegration,
    isLoading: isUpdatingIntegration,
    error: integrationUpdateApiError,
  } = useUpdateIntegration()

  const [integrationName, setIntegrationName] = useState(
    (integrationData && integrationData.name) || "Integration's name"
  )

  useEffect(() => {
    if (integrationData && integrationData.name) {
      setIntegrationName(integrationData.name)
    }
  }, [integrationData])

  const handleSave = () => {
    updateIntegration(
      {
        requestBody: {
          name: integrationName,
          appId: parseInt(appId as string),
          credentials: [],
        },
        integrationId: `${integrationId}`,
      },
      {
        onSuccess: (newIntegration) => {
          sendIntegrationDataToParentWindow(
            (newIntegration && Number(newIntegration.id)) || 0,
            (newIntegration && newIntegration.name) || ''
          )
        },
      }
    )
  }

  // TODO: decide if on cancel we just close the window or if we want to redirect the user back to step1 of incoming data screen
  const handleCancel = () => {
    // Alt.:
    // window.close()
    navigate(
      appRoutes.incomingDataScreenEdit.makeUrl({
        appId: appId as string,
        integrationId: integrationId,
      })
    )
  }

  // In case of error when updating the integration
  useEffect(() => {
    if (integrationUpdateApiError || integrationDataApiError) {
      const error = integrationUpdateApiError || integrationDataApiError
      const errorMessage = parseApiError(error!)

      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: errorMessage.message,
          useTranslation: true,
        })
      )
    }
  }, [integrationUpdateApiError, integrationDataApiError])

  const isLoading = isUpdatingIntegration || isFetchingIntegrationData
  return (
    <>
      {isLoading ? <Loader $active $dimmer /> : null}
      {integrationData ? (
        <IncomingDataFinalStep
          integrationName={integrationName}
          onEditIntegrationName={setIntegrationName}
          onSave={handleSave}
          onCancel={handleCancel}
          isUpdatingIntegration={isUpdatingIntegration}
        />
      ) : null}
    </>
  )
}
