import { BbuCallbackRouteParams, BbuRouteParams } from 'LeadsBridgeApp'

export default {
  bridgeByUrl: {
    path: '/bc/:partnerName/:source/:destination',
    makeUrl: ({ partnerName, source, destination }: BbuRouteParams) =>
      `/bc/${partnerName}/${source}/${destination}`,
  },
  bridgeByUrlEmbedded: {
    path: '/ebc/:partnerName/:source/:destination',
    makeUrl: ({ partnerName, source, destination }: BbuRouteParams) =>
      `/ebc/${partnerName}/${source}/${destination}`,
  },
  bridgeByUrlCallback: {
    path: '/auth/:partnerFullName/callback',
    makeUrl: ({ partnerFullName }: BbuCallbackRouteParams) =>
      `/auth/${partnerFullName}/callback`,
  },
}
