import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../index'
import FbAppStoreService from '../../../services/FbAppStoreService'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Label,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import { LangFbAppStore } from '../../../lang/LangFbAppStore'
import { doPostMessage, Messages } from '../../../utils/PostMessages'

const PlatformAuthentication: React.FC<{
  setOpen: any
  setAPIresponse: any
}> = ({ setOpen, setAPIresponse }) => {
  const platformId: number = useSelector(
    (state: RootState) => state.appStore.platformId
  )
  const [loading, setLoading] = useState<boolean>(true)

  const [fields, setFields] = useState<any>({})
  const [helpUrl, setHelpUrl] = useState<string>('')
  const [labels, setLabels] = useState<any>({})

  const [formError, setFormError] = useState<string>('')

  useEffect(() => {
    let subscribed = true

    // helpUrl: "https://leadsbridge.com/documentation/google-sheets"
    // oauth2RedirectUrl: "https://leadsbridge.com/fbAppStore/v1/doOauth2"
    if (platformId > 0) {
      // setRedirectUrl(process.env.REACT_APP_V1_API + '/fbAppStore/v1/doOauth2');

      doPostMessage(Messages.PlatformInvoked)
      FbAppStoreService.getPlatformAuth(platformId)
        .then((res) => {
          if (subscribed && res.oauth2RedirectUrl) {
            // setRedirectUrl(res.oauth2RedirectUrl);
          } else {
            setHelpUrl(res.helpUrl)
            const newObj: any = {}
            Object.keys(res.authFields).map((key: string) => {
              newObj[key] = ''
            })
            setFields(newObj)
            setLabels(res.authFields)
          }
        })
        .catch((res) => {
          switch (res.response.data.errorCode) {
            case 101:
              setAPIresponse(res.response.data)
              setOpen(true)
              break
            default:
              return null
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      subscribed = false
    }
  }, [platformId])

  const doOauth2 = () => {
    const url =
      process.env.REACT_APP_V1_API +
      '/fbAppStore/v2/doOauth2?platformId=' +
      platformId
    const width = 1120
    const left = window.screen.width / 2 - width / 2
    const newWin = window.open(
      url,
      'ModalPopUp',
      `toolbar=no,
            scrollbars=no, location=no, statusbar=no, menubar=no,
            resizable=0, width=${width}, height=720, left=${left}, top=234`
    )
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      setFormError(LangFbAppStore.popupLocked)
      setTimeout(() => setFormError(''), 5000)
      doPostMessage(Messages.PlatformError)
    } else {
      doPostMessage(Messages.PlatformAuthorized)
    }
  }

  const doAuth = () => {
    let emptyField = false
    Object.values(fields).forEach((value: any) => {
      if (value === '') {
        emptyField = true
      }
    })
    if (emptyField) {
      setFormError('All fields are required')
      return
    }
    setLoading(true)
    FbAppStoreService.doPlatformAuth(fields, platformId)
      .then((r) => {
        doPostMessage(Messages.PlatformAuthorized)
      })
      .catch((err) => {
        doPostMessage(Messages.PlatformError)
        setFormError(err.response?.data?.errorMessage ?? 'Error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Segment style={{ border: 'none', boxShadow: 'none' }}>
      <Dimmer
        inverted
        active={platformId === 0 || loading}
        style={{
          marginTop: '' + '' + '50px',
        }}
      >
        <Loader>Loading</Loader>
      </Dimmer>

      {!loading && platformId !== 0 && (
        <Form
          style={{ paddingTop: '2em', paddingBottom: '2em' }}
          method={'POST'}
          error={formError !== ''}
        >
          <div style={{ textAlign: 'center' }}>
            <img
              alt={'Platform logo'}
              src={`https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-logos/logo${platformId}.png`}
            />
          </div>
          <br />

          {!fields || Object.keys(fields).length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ marginTop: '1em' }}
                color='blue'
                size={'large'}
                fluid
                onClick={doOauth2}
              >
                Authorize
              </Button>
            </div>
          ) : (
            <>
              {Object.keys(fields).map((key: string, index: number) => {
                return (
                  <Form.Input
                    key={'field-' + index}
                    label={labels[key] + ' *'}
                    onChange={(e) => {
                      const oldFields = { ...fields }
                      oldFields[key] = e.target.value
                      setFields(oldFields)
                    }}
                  />
                )
              })}
              <p style={{ opacity: 0.6 }}>
                <sup>*</sup> Fields mandatory
              </p>

              <a href={helpUrl} target={'_blank'} rel='noreferrer'>
                <Label>
                  <Icon name={'external alternate'} /> What are those fields?
                </Label>
              </a>
              <div>
                <Button
                  style={{ marginTop: '1em' }}
                  color='blue'
                  size={'large'}
                  fluid
                  className={loading ? 'loading' : ''}
                  onClick={doAuth}
                >
                  Authorize
                </Button>
              </div>
            </>
          )}

          {formError !== '' && (
            <Message error header='Error' content={formError} />
          )}
        </Form>
      )}
    </Segment>
  )
}

export default PlatformAuthentication
