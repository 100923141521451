import axios from 'axios'
import { Auth } from 'aws-amplify'
import { CognitoErrorResponse, PersistentBbuData } from 'LeadsBridgeApp'
import { getCustomErrorCognitoMessage } from '@app/utils/cognitoErrors'
import { useEffect, useRef, useState } from 'react'
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { endpoints, getApiErrorMessage } from '@app/api/config'
import { PersistentLocalStorageKeys } from '@app/enums/persistentLocalStorageKeys'
import { parseAuthUser } from '@app/features/auth/functions/parseAuthUser'
import {
  setAuthStateAction,
  setCurrentUserAction,
} from '@app/store/actions/UserActions'
import { trackEvent } from '@app/dataTracking'
import { BbuPartnersName } from '@app/enums/bridgeByUrl'

interface HandleOauthUserResponse {
  username: string
  password: string
  userStatus: string
  Pricing?: {
    cbPlanId: string
    mrr: number
  }
}

interface UseGenericBbuAuthParms {
  provider?: string
  authCode?: string
  requestUri?: string
  bbuData?: PersistentBbuData | null
}

export const useGenericBbuAuth = ({
  provider,
  authCode,
  requestUri,
  bbuData,
}: UseGenericBbuAuthParms) => {
  const dispatch = useAppDispatch()
  const [apiError, setApiError] = useState('')
  const isMountedRef = useRef(false)
  const [userStatus, setUserStatus] = useState('')

  const { authState } = useAppSelector((state) => state.user)

  // we want to
  const isNotSignedIn = authState === AuthState.SignIn
  const canProceed = authCode && provider && requestUri
  const canProceedWithNotLoggedInFlow = canProceed && isNotSignedIn

  useEffect(() => {
    isMountedRef.current = true
    // in case user is already logged our component will be unmounted and re-mounted
    // so let's handle this scenario in useEffect cleanup
    const authCancelRequest = axios.CancelToken.source()
    if (canProceedWithNotLoggedInFlow) {
      console.log('STARTING NOT LOGGED IN FLOW')
      axios
        .post<HandleOauthUserResponse>(
          endpoints.handleOauthNotLoggedUser,
          {
            provider: provider,
            authCode: authCode,
            requestUri: requestUri,
          },
          {
            cancelToken: authCancelRequest.token,
          }
        )
        .then((response) => {
          const { username, password, userStatus, Pricing } = response.data

          // we need to keep it persistent, because after login, component will be refreshed
          // and we need this information to handle the show or not of the survey
          // to consider if would be good to keep this info in cognito user attributes
          localStorage.setItem(
            PersistentLocalStorageKeys.bbuPersistentUserStatus,
            userStatus
          )
          Auth.signIn({
            username: username,
            password: password,
            validationData: [],
          })
            .then((res) => {
              if (isMountedRef.current) {
                const cognitoUser = parseAuthUser(res as CognitoUserInterface)
                dispatch(setAuthStateAction(AuthState.SignedIn))
                dispatch(setCurrentUserAction(cognitoUser))
                setUserStatus(userStatus)

                // User has just been registered and this is the first login
                // we run the SignUp trackEvent here (and not at the previus response level) so we can also attach userId received after the login
                // we also add some delay because we want to be sure user is added in redux store  and will be readed inside the trackEvent function
                console.log('track!')
                setTimeout(() => {
                  trackEvent({
                    eventName: 'SignUp',
                    feature: 'Authentication',
                    step: 'Signup',
                    params: {
                      custom: {
                        planId: Pricing?.cbPlanId,
                        mrr: (Pricing?.mrr || 0) / 100,
                        channel:
                          provider &&
                          BbuPartnersName[
                            provider as keyof typeof BbuPartnersName
                          ], // eg: "tt" for tiktok
                        sourceId: bbuData?.source?.id,
                        sourceName: bbuData?.source.name,
                        destinationId: bbuData?.destination?.id,
                        destinationName: bbuData?.destination?.name,
                      },
                    },
                  })
                }, 500)
              }
            })
            .catch((cognitoError: CognitoErrorResponse) => {
              if (isMountedRef.current) {
                setApiError(getCustomErrorCognitoMessage(cognitoError))
              }
            })
        })
        .catch((error) => {
          setApiError(
            getApiErrorMessage({
              error: error,
              fallbackMessage: 'We could not authenticate you',
            })
          )
        })
    }

    return () => {
      isMountedRef.current = false
      authCancelRequest.cancel('Aborted since user is already logged')
    }
  }, [canProceedWithNotLoggedInFlow])

  useEffect(() => {
    if (apiError) {
      trackEvent({
        eventName: 'UnexpectedErrorThrown',
        feature: 'Authentication',
        step: 'Signup',
        params: {
          custom: {
            errorDescription: apiError,
          },
        },
      })
    }
  }, [apiError])

  return {
    userStatus: userStatus,
    apiError: apiError,
  }
}
