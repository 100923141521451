import React, { useEffect, useState } from 'react'
import LoginV1Service from '../services/ApplicationV1Bridge'
import { Button, Dimmer, Loader, Message } from 'semantic-ui-react'
import QueryString from 'querystring'
import createPersistedState from 'use-persisted-state'
import { LangFbAppStore } from '@app/lang/LangFbAppStore'

const FbeCallback: React.FC = () => {
  const [callInProgress, setCallInProgress] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [errorStyle, setErrorStyle] = useState<string>('')
  const useRequestUriState = createPersistedState('requestUri')
  const usePersisteIsFlowForNbee = createPersistedState('isFlowForNbee')
  const [requestUri] = useRequestUriState('')

  // this allow us to understand if `setupInstance` needs to return a v1 or v2 url
  const [persistentIsFlowForNbee] = usePersisteIsFlowForNbee()

  useEffect(() => {
    const params = QueryString.parse(window.location.search.slice(1))
    const error: string = params.lb_error as string
    if (error) {
      const errorCode: string = params.lb_error_code as string
      const errorDescription: string = params.lb_error_description as string
      LoginV1Service.fbeCancel({
        error: error,
        errorCode: errorCode,
        errorDescription: errorDescription,
      })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setCallInProgress(false)
          window.location.replace(process.env.REACT_APP_V1_URL + '')
        })
    } else {
      LoginV1Service.setupInstance({ nbee: !!persistentIsFlowForNbee })
        .then((callback: { callbackPath: string }) => {
          localStorage.removeItem('isFlowForNbee')
          localStorage.removeItem('requestUri')
          window.location.replace(callback.callbackPath)
        })
        .catch((error) => {
          // Found here https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
          const errorMessage = error.response?.data?.errorMessage
            ? error.response.data.errorMessage
            : '-'
          if (errorMessage.includes('systemUser.RetrieveAccessTokenError')) {
            setErrorMessage(LangFbAppStore.messages.tokenError)
          } else {
            setErrorMessage(errorMessage)
            setErrorStyle('negative')
          }
        })
        .finally(() => {
          setCallInProgress(false)
        })
    }
  }, [])

  return (
    <>
      <Dimmer active={callInProgress} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {errorMessage !== '' && (
        <Message className={errorStyle}>
          <>
            {errorStyle === 'negative' && (
              <Message.Header>An error occurred!</Message.Header>
            )}
            <p>{errorMessage}</p>
            {requestUri && (
              <Button
                onClick={() => {
                  // `requestUri` comes from localStorage (stored as persistent state during previus step)
                  location.href = requestUri as string
                }}
              >
                Retry
              </Button>
            )}
          </>
        </Message>
      )}
    </>
  )
}

export default FbeCallback
