import React from 'react'

export const TestComponents: React.FC = () => {
  const isProduction = process.env.NODE_ENV !== 'development'
  if (isProduction) {
    // prevent this page to appear in production :) test
    return null
  }

  return <div></div>
}
