import { AxiosInstance, AxiosResponse } from 'axios'
import { FacebookParameters } from 'LeadsBridgeApp'
import { axiosInterceptor } from './axiosInterceptor'

export default class FbAppStoreService {
  // 25-may forse versione definitiva???
  public static getSheetsUrl: string = '/fbAppStore/v2/getSpreedSheets'
  public static getSpreedSheetTabsUrl: string =
    '/fbAppStore/v2/getSpreedSheetTabs' // TODO getSpreedSheetTabs ? era sbagliata.

  // TODO DA CONTROLLARE
  public static getPlatformAuthUrl: string = '/fbAppStore/v2/getPlatformAuth'
  public static doPlatformAuthUrl: string = '/fbAppStore/v2/doPlatformAuth'
  public static generateExternalBusinessIdUrl: string =
    '/fbAppStore/v2/generateExternalBusinessId'

  public static saveSheetSelectionUrl: string =
    '/fbAppStore/v2/doSpreedSheetConfig'

  public static saveSegmentSelectionUrl: string =
    '/fbAppStore/v2/doSegmentConfig'

  public static getSegmentTypesUrl: string = '/fbAppStore/v2/getSegmentTypes'
  public static segmentUrls: string = '/fbAppStore/v2/getSegments'

  // platform configuraiton
  public static getPlatformCapabilitiesUrl: string =
    '/fbAppStore/v2/getPlatformCapabilities'

  public static doPublishBridgesUrl: string = '/fbAppStore/v2/doPublishBridges'
  public static getSyncDataLinksUrl: string = '/fbAppStore/v2/getSyncDataLinks'

  // 25-may forse versione definitiva???
  static getSheets = async (platformId: number): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.getSheetsUrl +
            '?platformId=' +
            platformId
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static getSpreadSheetTabs = async (
    platformId: number,
    selectedSheetId: string
  ): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.getSpreedSheetTabsUrl +
            '?platformId=' +
            platformId +
            '&sheetId=' +
            selectedSheetId
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  // TODO DA RICONTROLLARE

  static getPlatformAuth = async (platformId: number): Promise<any> => {
    // console.log('getPlatformAuth platformId', platformId);
    const url =
      process.env.REACT_APP_V1_API + FbAppStoreService.getPlatformAuthUrl
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(url + '?platformId=' + platformId)
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static doPlatformAuth = async (
    fields: object,
    platformId: number
  ): Promise<any> => {
    const url =
      process.env.REACT_APP_V1_API + FbAppStoreService.doPlatformAuthUrl
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(url + '?platformId=' + platformId, {
          fields: fields,
        })
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static generateExternalBusinessId = async (): Promise<FacebookParameters> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.generateExternalBusinessIdUrl,
          {}
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static segmentTypes = async (platformId: number): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.getSegmentTypesUrl +
            '?platformId=' +
            platformId
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static getSegments = async (
    platformId: number,
    selectedSheetId: string
  ): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.segmentUrls +
            '?segmentType=' +
            selectedSheetId +
            '&platformId=' +
            platformId
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static saveSegmentSelection = async (
    platformId: number,
    selectedSheetId: string,
    selectedTabId: string
  ): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.saveSegmentSelectionUrl,
          {
            segmentType: selectedSheetId,
            segmentId: selectedTabId,
            platformId: platformId,
          }
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static saveSheetSelection = async (
    platformId: number,
    selectedSheetId: string,
    selectedTabId: string
  ): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.saveSheetSelectionUrl,
          {
            sheetId: selectedSheetId,
            tabId: selectedTabId,
          }
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  // platform configuration
  static getPlatformCapabilities = async (platformId: number): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API +
            FbAppStoreService.getPlatformCapabilitiesUrl +
            '?platformId=' +
            platformId
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static doPublishBridges = async (
    platformId: number,
    entities: string[],
    limitedDataUse: boolean,
    adsDeliveryOptimization: boolean
  ): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .post(
          process.env.REACT_APP_V1_API + FbAppStoreService.doPublishBridgesUrl,
          {
            platformId: platformId,
            entities: entities,
            limitedDataUse: limitedDataUse,
            adsDeliveryOptimization: adsDeliveryOptimization,
          }
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }

  static getSyncDataLinks = async (): Promise<any> => {
    return await axiosInterceptor().then((ax: AxiosInstance) => {
      return ax
        .get(
          process.env.REACT_APP_V1_API + FbAppStoreService.getSyncDataLinksUrl
        )
        .then((res: AxiosResponse) => {
          return res.data
        })
    })
  }
}
