import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PersistentSessionStorageKeys } from '@app/enums/persistentLocalStorageKeys'
import { Loader } from '@components/Basic/Loader'
import { Message } from '@components/Basic/Message'
import { parseApiError } from '@app/api/utils/error'
import { appRoutes } from '@app/routes'
import { trackEvent } from '@app/dataTracking'
import { useAuthFromGrantCode } from '@app/api/postAuthfromGrantCode'

export const IntegrationAuthCallback2: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    mutate: postAuthGrantCode,
    isLoading: isLoadingAuthGrantCode,
    error: authGrantCodeApiError,
    data: authGrantCodeData,
  } = useAuthFromGrantCode()

  // Preparing data required to proceed
  const appId = sessionStorage.getItem(
    PersistentSessionStorageKeys.integrationAuthFlowAppId
  )
  const integrationId = sessionStorage.getItem('instanceId')

  const paramsFromUrl = new URLSearchParams(window.location.search)
  const paramsObject = Object.fromEntries(paramsFromUrl)

  const appIdInt = Number(appId)
  const integrationIdInt = Number(integrationId)

  const payloadArray = Object.keys(paramsObject).map((key) => ({
    key: key,
    value: paramsObject[key],
  }))

  useEffect(() => {
    postAuthGrantCode(
      {
        appId: appIdInt,
        integrationId: integrationIdInt || undefined,
        payload: payloadArray,
      },
      {
        onSuccess: (data) => {
          const integrationData = data?.data?.integration
          if (integrationData && integrationData.id && appId) {
            trackEvent({
              eventName: 'AuthorizeSuccess',
              feature: 'NBEE',
              step: 'Integration',
              params: {
                appId: parseInt(appId, 10),
                integrationId: integrationData.id,
                integrationName: integrationData.name,
                oauth: true,
              },
            })

            navigate(
              appRoutes.integrationAuthScreenEditStep2.makeUrl({
                integrationId: `${integrationData.id}`,
                appId: appId,
              })
            )
          }
        },
      }
    )
  }, [])

  useEffect(() => {
    if (!appId || !integrationId || authGrantCodeApiError) {
      const errorCode = authGrantCodeApiError
        ? parseApiError(authGrantCodeApiError).code
        : 'UI-Error'
      const errorDescription = authGrantCodeApiError
        ? parseApiError(authGrantCodeApiError).message
        : 'Missing appId or legacyIntegrationId'

      trackEvent({
        eventName: 'AuthorizeFailure',
        feature: 'NBEE',
        step: 'Integration',
        params: {
          appId: appId ? parseInt(appId, 10) : undefined,
          integrationId: authGrantCodeData?.data?.integration?.id,
          integrationName: authGrantCodeData?.data?.integration?.name,
          oauth: true,
          custom: {
            errorCode: errorCode,
            errorDescription: errorDescription,
          },
        },
      })
    }
  }, [appId, integrationId, authGrantCodeApiError])

  if (!appId) {
    return (
      <Message $status={'error'}>
        App ID not found. This probably means you have not started the
        Authorization flow from a trusted source (eg. NBEE) or session storage
        is empty.
      </Message>
    )
  }

  return authGrantCodeApiError ? (
    <Message $status={'error'}>
      {parseApiError(authGrantCodeApiError).message}
    </Message>
  ) : (
    <div
      style={{
        position: 'relative',
        height: 200,
      }}
    >
      <Loader $active $dimmer $size={'large'} />
    </div>
  )
}
