import { BrowserOptions } from '@sentry/react'
type SentryIgnoreList = Pick<
  BrowserOptions,
  'ignoreErrors' | 'denyUrls' | 'allowUrls'
>

// Keep ignore list updated as specified here -> https://docs.sentry.io/clients/javascript/tips/
export const ignoreList: SentryIgnoreList = {
  // regex should match both https://app.leadsbridge.com  and https://staging-app.leadsbridge.com
  allowUrls: [/https?:\/\/([a-z0-9-]+[.])?leadsbridge\.com/],
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'Script error.',
    '_avast_submit',
    //
    // custom from LB here...
    'Pending sign-in attempt already in progress', // internal cognito
    'consentCookieParser is not defined', // some marketing tracking script injected in page
    'TypeError: Failed to fetch',
    'Failed to fetch',
  ],
  denyUrls: [
    /pagead\/js/i,
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    //
    // custom LB here:
    /cdn\.segment\.com/i,
    /static\.woopra\.com/i,
    /snap\.licdn\.com/i,
  ],
}
