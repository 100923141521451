import React, { Dispatch, useEffect, useState } from 'react'
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react'
import FbAppStoreService from '../../../services/FbAppStoreService'
import { sendToast } from '../../../store/actions/ApplicationConfigurationActions'
import { useDispatch } from 'react-redux'
import Confetti from '../../../components/Confetti'
import styled from 'styled-components'

const IconSync = styled(Icon)`
  font-size: 15px;
  position: absolute;
  bottom: 0;
  right: -8px;
  background: white;
  border-radius: 100%;
  //border: 2px solid #1877F2;
  padding: 3px;
`
const IconStepStyled = styled.div`
  box-shadow:
    0 1px 1px 0 rgb(66 66 66 / 8%),
    0 1px 3px 1px rgb(66 66 66 / 16%);
  position: relative;
  display: flex;
  margin: 5px auto 1em auto;
  padding: 10px;
  border-radius: 10px;
  i {
    margin-bottom: 0 !important;
  }
  .sync {
    font-size: 25px !important;
    bottom: 5px;
    right: 5px;
    color: #1877f2;
    padding: 5px;
  }
`
const CardGroupStyled = styled(Segment)`
  max-width: 640px;
  margin: 2em auto !important;
  background-color: #f3f4f5 !important;
`
const SyncData: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const [contactsLink, setContactsLink] = useState<string>('')
  const [eventsLink, setEventsLink] = useState<string>('')
  const [link, setLink] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    FbAppStoreService.getSyncDataLinks()
      .then((res) => {
        setContactsLink(res.contactsLink)
        setEventsLink(res.eventsLink)
        setLink(res.leadsbridgeLink)
      })
      .catch((e) => {
        const error = e.response?.data?.errorMessage
          ? 'An internal error occurred, please try again later.'
          : 'Unknown Error'
        dispatch(
          sendToast({
            title: 'An error occurred',
            messages: [error],
            color: 'negative',
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <div style={{ textAlign: 'center' }}>
      {isLoading ? <></> : <Confetti />}
      <Header as='h3' icon>
        <IconStepStyled className={'fitContent'}>
          <Icon name='envelope' style={{ color: '#EDBA71' }} />
          <IconSync name='sync' loading />
        </IconStepStyled>
        We started syncing your data - You can proceed with the navigation.
        <Header.Subheader style={{ marginTop: '10px' }}>
          We will send you an email once the sync is completed.
        </Header.Subheader>
      </Header>
      <Dimmer
        inverted
        active={isLoading}
        style={{
          marginTop: '' + '' + '50px',
        }}
      >
        <Loader>Loading</Loader>
      </Dimmer>

      <p className={'text-secondary'}>
        Please note that the process will take some time, based on the amount of
        your data.
      </p>
      <CardGroupStyled>
        <h5>
          You can now return to Facebook - explore your data, as soon as the
          sync is done.
        </h5>
        <div className={'my-1'}>
          <a href={link} target='_blank' rel='noreferrer'>
            Explore the LeadsBridge platform{' '}
            <Icon style={{ marginLeft: 5 }} name={'external'} />
          </a>
        </div>
      </CardGroupStyled>
    </div>
  )
}

export default SyncData
