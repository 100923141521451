import { createReducer } from '@reduxjs/toolkit'
import {
  ApplicationConfigurationState,
  SEND_TOAST,
  SEND_ALERT_MESSAGE,
  TOGGLE_SIDEBAR,
  SET_LOGIN_LAYOUT,
  CLOSE_ALERT_MESSAGE,
} from '../types/ApplicationConfigurationTypes'

const initialState: ApplicationConfigurationState = {
  displayVerticalSidebar: false,
  toast: null,
  alert: null,
  user: undefined,
  loginLayout: null,
}

export const ApplicationConfigurationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        TOGGLE_SIDEBAR,
        (state: ApplicationConfigurationState, action: any) => {
          state.displayVerticalSidebar = !state.displayVerticalSidebar
        }
      )
      .addCase(
        SEND_TOAST,
        (state: ApplicationConfigurationState, action: any) => {
          state.toast = action.payload
        }
      )
      .addCase(
        SEND_ALERT_MESSAGE,
        (state: ApplicationConfigurationState, action: any) => {
          state.alert = action.payload
        }
      )
      .addCase(
        CLOSE_ALERT_MESSAGE,
        (state: ApplicationConfigurationState, action: any) => {
          state.alert = null
        }
      )
      .addCase(
        SET_LOGIN_LAYOUT,
        (state: ApplicationConfigurationState, action: any) => {
          state.loginLayout = action.payload
        }
      )
  }
)
