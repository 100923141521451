import React, { useEffect, useState } from 'react'
import { SimpleBridgeBuilderForm } from '@features/nbee/SimpleBridgeBuilderForm'
import { useGetBridgeById } from '@app/api/getBridgeById'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateBridge } from '@app/api/updateBridge'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { appRoutes } from '@app/routes'
import { useAppDispatch } from '@app/store/hooks'
import { parseApiError } from '@app/api/utils/error'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { NbeeError } from '@features/nbee/NbeeError'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useSetupBridgeById } from '@app/api/postSetupBridge'
import { FormikHelpers } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { useFetchAllApps } from '@app/api/getAllApps'

export const Step1Edit: React.FC = () => {
  const { bridgeId } = useParams<{ bridgeId: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [bridgeFormikProps, setBridgeFormikProps] = useState<{
    formValues: BridgeFormValues
    formikHelpers: FormikHelpers<BridgeFormValues>
  } | null>(null)

  const {
    data: allApps,
    isLoading: isLoadingApps,
    error: allAppsApiError,
  } = useFetchAllApps()

  const {
    data: bridgeFormValues,
    isLoading: isLoadingBridge,
    error: bridgeApiError,
  } = useGetBridgeById(bridgeId)

  const apiInitialDataError = allAppsApiError || bridgeApiError

  // Update bridge
  const {
    mutate: updateBridge,
    data: updateBridgeResponse,
    error: updateBridgeError,
    isLoading: isUpdatingBridge,
  } = useUpdateBridge()

  // Setup bridge
  const {
    mutate: setupBridge,
    data: bridgeSetupResponse,
    error: bridgeSetupApiError,
    isLoading: isSettingUpBridge,
  } = useSetupBridgeById()

  const isSavingApi = isSettingUpBridge || isUpdatingBridge
  const apiError = bridgeSetupApiError || updateBridgeError
  const updatedBridgeId = updateBridgeResponse?.data?.bridge?.id

  useEffect(() => {
    if (updatedBridgeId) {
      setupBridge({ bridgeId: updatedBridgeId })
    }
  }, [updatedBridgeId])

  // when setup bridge is completed we can redirect the user to next step
  useEffect(() => {
    if (bridgeSetupResponse) {
      navigate(appRoutes.nbeeStep2.makeUrl(bridgeId as string))
    }
  }, [bridgeSetupResponse])

  // if error we notify the user
  useEffect(() => {
    if (apiError) {
      const parsedError = parseApiError(apiError)
      dispatch(
        sendToast({
          title: 'Error',
          message: parsedError.message,
          color: 'negative',
        })
      )

      if (bridgeFormikProps) {
        bridgeFormikProps.formikHelpers.setSubmitting(false)

        trackEvent({
          eventName: 'UnexpectedErrorThrown',
          feature: 'NBEE',
          step: 'Apps',
          params: {
            ...makeNbeeTrackingParams(bridgeFormikProps.formValues),
            custom: {
              errorCode: parsedError.code,
              errorDescription: parsedError.message,
            },
          },
        })
      }
    }
  }, [apiError])

  return isLoadingBridge || isLoadingApps ? (
    <LoadingStep activeStepIndex={0} />
  ) : bridgeFormValues && allApps ? (
    <SimpleBridgeBuilderForm
      isSavingApi={isSavingApi}
      initialValues={bridgeFormValues}
      onSubmit={(formValues, formikHelpers) => {
        const updateBridgeData = transformBridgeFormValuesToApiSaveBridgeBody(
          formValues,
          { step: 1 }
        )

        // we store all formik handlers in order to access them in the component scope
        setBridgeFormikProps({
          formValues: formValues,
          formikHelpers: formikHelpers,
        })

        updateBridge({
          bridgeData: updateBridgeData,
          bridgeId: bridgeId as string,
        })
      }}
      isFirstBridge={bridgeFormValues?.manualStatus === 1} // checks if it's the first bridge created by this user; in that case we will NOT want to display the edit bar.
    />
  ) : apiInitialDataError ? (
    <NbeeError
      activeStepIndex={0}
      errorCode={parseApiError(apiInitialDataError).code}
      statusCode={parseApiError(apiInitialDataError).status}
      errorMessage={parseApiError(apiInitialDataError).message}
    />
  ) : null
}
