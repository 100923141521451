import {
  ApplicationConfigurationTypes,
  SEND_TOAST,
  TOGGLE_SIDEBAR,
  SET_LOGIN_LAYOUT,
  SEND_ALERT_MESSAGE,
  CLOSE_ALERT_MESSAGE,
} from '../types/ApplicationConfigurationTypes'
import { Alert, Toast } from 'LeadsBridgeApp'

export const toggleVerticalSidebar = (): ApplicationConfigurationTypes => {
  return {
    type: TOGGLE_SIDEBAR,
  }
}
export function sendToast(toast: Toast): ApplicationConfigurationTypes {
  return {
    type: SEND_TOAST,
    payload: toast,
  }
}
export function sendAlertMessage(
  alertMessage: Alert
): ApplicationConfigurationTypes {
  return {
    type: SEND_ALERT_MESSAGE,
    payload: alertMessage,
  }
}

export const closeAlertMessage = (): ApplicationConfigurationTypes => {
  return {
    type: CLOSE_ALERT_MESSAGE,
  }
}

export const setLoginLayout = (
  source: string
): ApplicationConfigurationTypes => {
  return {
    type: SET_LOGIN_LAYOUT,
    payload: source,
  }
}
