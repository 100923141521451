import { Hub } from '@aws-amplify/core'
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components'
import { doPostMessage, Messages } from '../utils/PostMessages'
import { Auth } from 'aws-amplify'
import Cookies from 'universal-cookie'
import { PersistentCookiesKeys } from '@app/enums/persistentCookiesKeys'
import { trackEvent } from '@app/dataTracking'
import LoginV1Service from '@app/services/ApplicationV1Bridge'

const persistAuthExpiration = () => {
  const date = new Date()
  localStorage.setItem(
    'auth-expiration',
    String(date.setDate(date.getDate() + 1))
  )
}

const trackSignInEvent = () => {
  setTimeout(() => {
    // add some delay because we want to be sure user is added in redux store
    // and will be readed inside the trackEvent function
    trackEvent({
      eventName: 'SignIn',
      feature: 'Authentication',
      step: 'Signin',
    })
  }, 500)
}

export default class AuthService {
  static setSessionCookie = async () => {
    await Auth.currentSession().then(async (session: any) => {
      const accessToken = session.getAccessToken()
      const jwt = accessToken.getJwtToken()
      await LoginV1Service.setupSessionCookie(
        jwt,
        process.env.REACT_APP_V1_COOKIE_DOMAIN ?? ''
      )
    })
  }

  static onLogin = async (keepMeLogged: boolean) => {
    const urlPath: string = window.location.pathname
    await AuthService.setSessionCookie()
    // to be safe, every time an user does a login we delete the LB_imp cookie (SB-522)
    const cookies = new Cookies()
    cookies.remove(PersistentCookiesKeys.LbImp, {
      path: '/',
      domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
    })

    if (!keepMeLogged) {
      persistAuthExpiration()
    }
    if (urlPath.includes('/fbAppStore')) {
      doPostMessage(Messages.AppInvoked)
      doPostMessage(Messages.UserLogged)
    }
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignedIn,
      data: null,
    })
    trackSignInEvent()
  }

  static onLoginAsync = async (keepMeLogged: boolean) => {
    return await AuthService.setSessionCookie().then(() => {
      if (!keepMeLogged) {
        persistAuthExpiration()
      }
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignedIn,
        data: null,
      })
    })
    trackSignInEvent()
  }
}
