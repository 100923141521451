import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useVerifyLink } from '@app/api/postVerifyLink'
import { Loader } from '@components/Basic/Loader'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { StyledAuthorizationPopup } from '@features/nbee/IntegrationCredentialsContent/styled'
import { useTranslation } from 'react-i18next'

export const IntegrationAuthVerifiedEmailScreenLegacy: React.FC = () => {
  const { Wrapper, Header, Body, Title, SubTitle } = StyledAuthorizationPopup
  const { t } = useTranslation()
  const location = useLocation()
  const queryStringParam = queryString.parse(location.search) as {
    code: string
  }

  const {
    data: verifyLinkResponse,
    mutate: verifyLink,
    isLoading: verifyLinkLoading,
    isError: verifyLinkError,
  } = useVerifyLink()

  useEffect(() => {
    if (queryStringParam.code) {
      verifyLink(queryStringParam)
    }
  }, [])

  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' alt={'leadsbridge-logo'} />
      </Header>
      <Body>
        <>
          <Title>{t('emailValidationLegacyExperience.pageTitle')}</Title>
          {verifyLinkLoading && (
            <>
              <SubTitle>
                {t('emailValidationLegacyExperience.loadingMessage')}
              </SubTitle>
              <Loader $active $dimmer $size={'large'} />
            </>
          )}
          {verifyLinkResponse && (
            <SubTitle>
              {t('emailValidationLegacyExperience.successMessage')}
            </SubTitle>
          )}
          {verifyLinkError && (
            <SubTitle>
              {t('emailValidationLegacyExperience.errorMessage')}
            </SubTitle>
          )}
        </>
      </Body>
    </Wrapper>
  )
}
