import React, { useEffect, useState } from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import QueryString from 'querystring'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import AuthService from '../services/AuthService'
import { Link } from 'react-router-dom'

const SamlRedirect: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loginError, setLoginError] = useState('')

  useEffect(() => {
    const handleSamlLogin = async () => {
      const params = QueryString.parse(window.location.search.slice(1))
      const samlProvider = sessionStorage.getItem('saml-provider')

      if (!params.code || !samlProvider) {
        setLoginError('Missing code or provider name')
        return
      }

      const redirect_uri = `${process.env.REACT_APP_SAML_REDIRECT_URL}`
      const body = new URLSearchParams()
      body.append('grant_type', 'authorization_code')
      body.append('client_id', process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID!)
      body.append('redirect_uri', redirect_uri)
      body.append('code', params.code.toString())

      try {
        // checking if user exists in cognito
        const responseCognito = await axios.post<{ access_token: string }>(
          `${process.env.REACT_APP_COGNITO_URL}.amazoncognito.com/oauth2/token`,
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        const accessToken = responseCognito.data.access_token
        if (!accessToken) {
          setLoginError('Missing access token from cognito oauth2')
          return
        }

        // login user in our backend
        const responseHandleSamlUser = await axios.post<{
          username: string
          password: string
        }>(
          process.env.REACT_APP_V1_API + '/auth/v2/handleSamlUser',
          {
            provider: samlProvider,
          },
          {
            headers: { access_token: accessToken },
          }
        )
        const { username, password } = responseHandleSamlUser.data
        if (!username || !password) {
          setLoginError('We could not find your user in our system')
          return
        }

        const signInResponse = await Auth.signIn({
          username: username,
          password: password,
          validationData: [],
        })

        // setting cookies and other login stuff
        if (signInResponse) {
          AuthService.onLogin(false)
          // we give some time to onLogin, before redirecting user to app
          setTimeout(() => {
            window.location.replace(process.env.REACT_APP_V1_URL || '/')
          }, 500)
        } else {
          setLoginError(
            'Login failed: we could validate your SAML session, but it seems we failed to create a valid session for your user'
          )
        }
      } catch (err) {
        setLoginError(
          'Something is wrong, we could not validate your SAML token'
        )
      }
    }

    handleSamlLogin()
  }, [])

  useEffect(() => {
    if (loginError) {
      setLoading(false)
    }
  }, [loginError])

  return (
    <div>
      {loading ? (
        <Segment style={{ minHeight: '100vh' }}>
          <Dimmer active={true} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      ) : (
        <div className={'ui negative message text-center'}>
          {/* TODO: add valid UI */}
          <i
            className={'exclamation triangle large icon'}
            style={{ marginBottom: '1rem' }}
          />
          <div className='header' style={{ marginBottom: '1rem' }}>
            SAML authentication failed
          </div>
          {/* <p className={'input-error'}>SAML authentication failed</p> */}
          <Link to={'/saml'}>Login again</Link>
        </div>
      )}
    </div>
  )
}

export default SamlRedirect
// https://localhost/auth/redirect?code=35a1770f-3369-4af7-818e-4fc73628e54c
