import axios, { AxiosRequestHeaders } from 'axios'
import {
  getCognitoPersistentSession,
  fetchAndPersistCognitoSession,
} from '@app/services/apiAuthClient/utils'
import { appRoutes } from '@app/routes'
import Cookies from 'universal-cookie'
import { PersistentCookiesKeys } from '@app/enums/persistentCookiesKeys'
import { matchPath } from 'react-router-dom'

export const apiAuthClient = axios.create()

// Sign outgoing requests
apiAuthClient.interceptors.request.use(async (config) => {
  // getting cognito session from cache (cookie).
  // If no cache is found, we call cognito sdk endpoints and cache it
  const cognitoSessionData =
    getCognitoPersistentSession() || (await fetchAndPersistCognitoSession())

  if (!cognitoSessionData) {
    // something is wrong with cognito session retrival, we can't enhance axios config
    return config
  }

  // setting required axios headers
  const { accessToken, deviceKey } = cognitoSessionData
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders
  }
  config.headers.Authorization = `Bearer ${accessToken}`
  config.headers['Content-Type'] = 'application/json'
  config.headers['device-id'] = deviceKey

  // https://leadsbridge.atlassian.net/browse/SB-843
  const paths = [
    appRoutes.nbeeStep1Edit.path,
    appRoutes.nbeeStep2.path,
    appRoutes.nbeeStep3.path,
  ]

  const match = paths
    .map((path) => matchPath({ path: path }, location.pathname))
    .find((m) => m !== null && m !== undefined)

  const bridgeId = match?.params?.bridgeId
  if (bridgeId) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders
    }
    config.headers['X-bridge-id'] = bridgeId
  }

  // user impersonification (SB-522)
  const cookies = new Cookies()
  const impValue = cookies.get(PersistentCookiesKeys.LbImp)
  if (impValue) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders
    }
    config.headers['X-logged-as-Id'] = impValue
  }

  return config
})

// Intercept incoming reponses
apiAuthClient.interceptors.response.use(undefined, (error) => {
  // we might want to log user out from our app in case api returns 401 (unauthorized)
  // I will keep this one disabled in development/debug
  const currentPath = window.location.pathname
  if (error.response?.status === 401) {
    const logoutUrl = `${window.location.origin}${appRoutes.logout.makeUrl({
      redirectTo: currentPath,
      toastMessage: {
        message: "You've been logged out.", // This is a placeholder message, it's handled by phrase in the logout page
        type: 'positive',
      },
    })}`
    window.location.assign(logoutUrl)
  }

  // return default axios error
  return Promise.reject(error)
})
