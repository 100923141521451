import { Alert, Toast } from 'LeadsBridgeApp'
import { CognitoUserInterface } from '@aws-amplify/ui-components'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SEND_TOAST = 'SEND_TOAST'
export const SEND_ALERT_MESSAGE = 'SEND_ALERT_MESSAGE'
export const CLOSE_ALERT_MESSAGE = 'CLOSE_ALERT_MESSAGE'
export const SET_LOGIN_LAYOUT = 'SET_LOGIN_LAYOUT'

type LoginLayout = 'generic' | 'partner' | 'bridge-by-url'

export interface ApplicationConfigurationState {
  displayVerticalSidebar: boolean
  toast: Toast | null
  alert: Alert | null
  user: CognitoUserInterface | undefined
  loginLayout: LoginLayout | null
}

interface ToggleVerticalSidebar {
  type: typeof TOGGLE_SIDEBAR
}

interface SendToast {
  type: typeof SEND_TOAST
  payload: Toast
}
interface SendAlertMessage {
  type: typeof SEND_ALERT_MESSAGE
  payload: Alert
}

interface CloseAlertMessage {
  type: typeof CLOSE_ALERT_MESSAGE
}
interface SetLoginLayout {
  type: typeof SET_LOGIN_LAYOUT
  payload: string
}

export type ApplicationConfigurationTypes =
  | ToggleVerticalSidebar
  | SendToast
  | SendAlertMessage
  | SetLoginLayout
  | CloseAlertMessage
