import React, { useEffect } from 'react'
import { IncomingDataInitialStep } from '@features/nbee/IncomingDataContent/IncomingDataInitialStep'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { IncomingDataParams } from 'LeadsBridgeApp'
import { useGetIntegrationListener } from '@app/api/getIntegrationListener'
import { Loader } from '@app/components/Basic/Loader'
import {
  setIntegrationListenerAction,
  setIntegrationListenerStatus,
} from '@app/store/actions/IntegrationListenerActions'
import { useAppDispatch } from '@app/store/hooks'
import { parseApiError } from '@app/api/utils/error'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { useUpdateIntegrationListener } from '@app/api/updateIntegrationListener'

export const IncomingDataScreen: React.FC = () => {
  const dispatch = useAppDispatch()

  const params = useParams<IncomingDataParams>()
  const appId = params?.appId
  const integrationId = params?.integrationId
  const navigate = useNavigate()

  const {
    data: integrationListenerData,
    isLoading: isGettingIntegrationListener,
    error: errorGettingIntegrationListener,
  } = useGetIntegrationListener(integrationId)

  const {
    mutate: updateIntegrationListener,
    isLoading: isUpdatingIntegrationListener,
    error: errorUpdatingIntegrationListener,
  } = useUpdateIntegrationListener()

  const isLoading =
    isGettingIntegrationListener || isUpdatingIntegrationListener

  const isError =
    errorGettingIntegrationListener || errorUpdatingIntegrationListener

  const handleContinue = () => {
    if (
      integrationListenerData?.fields &&
      integrationListenerData.fields.length > 0
    ) {
      navigate(
        appRoutes.incomingDataScreenFields.makeUrl({
          appId: appId as string,
          integrationId: integrationId,
        })
      )
    } else {
      updateIntegrationListener(
        {
          requestBody: {
            status: 1,
          },
          integrationId: `${integrationId}`,
        },
        {
          onSuccess: () => {
            dispatch(setIntegrationListenerStatus(1))
            navigate(
              appRoutes.incomingDataScreenFields.makeUrl({
                appId: appId as string,
                integrationId: integrationId,
              })
            )
          },
        }
      )
    }
  }

  // dispatch integration listener data to redux store
  useEffect(() => {
    if (integrationListenerData) {
      dispatch(setIntegrationListenerAction({ data: integrationListenerData }))
    }
  }, [integrationListenerData, dispatch])

  // In case of error when getting the integration listener
  useEffect(() => {
    if (isError) {
      const error = isError
      const errorMessage = parseApiError(error!)

      dispatch(
        sendAlertMessage({
          isDismissable: false,
          message: errorMessage.message,
          useTranslation: true,
        })
      )
    }
  }, [isError])

  return (
    <>
      {isLoading || errorGettingIntegrationListener ? (
        <Loader $active={isLoading} $dimmer />
      ) : null}
      <IncomingDataInitialStep
        appLogoUri={
          'https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo168.png'
        }
        onContinueRequest={handleContinue}
      />
    </>
  )
}
