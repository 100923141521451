import { ThankYouPageState } from '@app/pages/ThankYouPage'

export default {
  pricing: {
    path: `/pricing`,
    makeUrl: () => `/pricing`,
    makeUrlWithState: (
      state: { prevPath: string },
      reasonId?: string,
      minimumPlanRequired?: number
    ) => ({
      pathname: '/pricing/',
      search:
        reasonId && minimumPlanRequired
          ? `reason=${reasonId}&suggestPlan=${minimumPlanRequired}`
          : '',
      state: { ...state },
    }),
  },
  checkout: {
    path: `/checkout`,
    makeUrl: (planId?: string) => `/checkout${planId ? `/?pid=${planId}` : ''}`,
  },
  checkoutThankYouPage: {
    path: `/checkout/success`,
    makeUrl: (state: ThankYouPageState) => ({
      pathname: `/checkout/success`,
      state: { ...state },
    }),
  },
}
