import axios, { AxiosRequestHeaders } from 'axios'

export const apiPublicClient = axios.create()

apiPublicClient.interceptors.request.use(async (config) => {
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders
  }
  config.headers['Content-Type'] = 'application/json'
  return config
})
