import React from 'react'
import { StepCompleted } from '@features/nbee/StepCompleted'
import { useParams } from 'react-router-dom'
import { useGetBridgeById } from '@app/api/getBridgeById'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { NbeeError } from '@features/nbee/NbeeError'
import { parseApiError } from '@app/api/utils/error'
import { useFetchConnectedApps } from '@app/api/getConnectedApps'
import { useGetRedirectUriBySourceAndDestination } from '@app/api/getRedirectUriBySourceAndDestination'

export type NbeeStepCompletedState = {
  success: boolean
  firstBridge?: boolean
}

export const Step3: React.FC = () => {
  const { bridgeId } = useParams()

  const {
    data: bridgeFormValues,
    isLoading: isLoadingBridge,
    error: errorGettingBridge,
  } = useGetBridgeById(bridgeId)

  // we need for app full names
  const { data: connectedApps, isLoading: isLoadingConnectedApps } =
    useFetchConnectedApps()

  // if (!state?.success) {
  //   // we are not allowing user seeing this page without coming from a step2
  //   // where set the location state `success` as true
  //   return <Redirect to={appRoutes.nbeeStep2.makeUrl(bridgeId)} />
  // }

  const sourceId = bridgeFormValues?.source.appId
  const destinationId = bridgeFormValues?.destination.appId
  const sourceAppName = sourceId
    ? (connectedApps || []).find(({ id }) => id === sourceId)?.name
    : undefined
  const destinationAppName = sourceId
    ? (connectedApps || []).find(({ id }) => id === destinationId)?.name
    : undefined

  const IsTestEnableProp = bridgeFormValues?.ui?.testEnabled

  const { data: redirectUri, isLoading: isLoadingBridgeUri } =
    useGetRedirectUriBySourceAndDestination(sourceId, destinationId)

  const isLoadingAll =
    isLoadingBridge || isLoadingConnectedApps || isLoadingBridgeUri

  return isLoadingAll ? (
    <LoadingStep activeStepIndex={2} />
  ) : bridgeFormValues && connectedApps && redirectUri ? (
    <StepCompleted
      bridgeId={bridgeId || ''}
      bridgeName={bridgeFormValues.name}
      sourceName={sourceAppName}
      destinationName={destinationAppName}
      redirectUri={redirectUri?.uri}
      isTestEnabledProp={IsTestEnableProp}
    />
  ) : errorGettingBridge ? (
    <NbeeError
      activeStepIndex={2}
      errorCode={parseApiError(errorGettingBridge).code}
      statusCode={parseApiError(errorGettingBridge).status}
      errorMessage={parseApiError(errorGettingBridge).message}
    />
  ) : null
}
