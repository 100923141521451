import React, { useEffect, useState } from 'react'
import { FieldsMappingForm } from '@features/nbee/FieldsMappingForm'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { NbeeError } from '@features/nbee/NbeeError'
import { parseApiError } from '@app/api/utils/error'
import { useGetBridgeById } from '@app/api/getBridgeById'
import { useUpdateBridge } from '@app/api/updateBridge'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetBridgeAllFields } from '@app/api/getBridgeAllFields'
import {
  makeFieldsMappingInitialValuesAndListNotMatchingFields,
  FieldMappingMatched,
  useNotifyUserOfMissingMappedFields,
} from '@features/nbee/FieldsMappingForm/initialStateUtils'
import { useAppDispatch } from '@app/store/hooks'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { appRoutes } from '@app/routes'
import { NbeeStepCompletedState } from '@app/pages/Nbee/Step3'
import { usePublishBridge } from '@app/api/publishBridge'
import { FormikHelpers } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { Button } from '@components/Basic/Button'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useGetUserInfo } from '@app/api/getUserInfo'

export const Step2: React.FC = () => {
  const params = useParams<{ bridgeId: string }>()
  const bridgeId = parseInt(params.bridgeId as string, 10)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    data: user,
    isLoading: isLoadingUser,
    error: userError,
  } = useGetUserInfo()

  const {
    data: bridgeFormValues,
    error: errorGettingBridge,
    refetch: onRefetchBridgeFormValues,
    isLoading: isLoadingBridge,
    isRefetching: isReloadingBridge,
  } = useGetBridgeById(`${bridgeId}`, true)

  const {
    data: allBridgeFields,
    error: allBridgeFieldsError,
    isLoading: loadingAllBridgeFields,
    isRefetching: isReloadingAllBridgeFields,
  } = useGetBridgeAllFields(bridgeId)

  // Update bridge hook
  const {
    mutate: updateBridge,
    error: updateBridgeError,
    isLoading: isUpdatingBridge,
  } = useUpdateBridge()

  // Publish bridge hook
  const {
    mutate: publishBridge,
    data: publishBridgeResponse,
    error: publishBridgeApiError,
    isLoading: isPublishingBridge,
  } = usePublishBridge()

  // prepare data for UI
  // we are not checking `isReloadingAllBridgeFields` here because we want to leave that loading state internal to the form
  // in this way non saved mapping (form state) will not be lost
  const isLoadingAll =
    isLoadingBridge || isReloadingBridge || loadingAllBridgeFields

  const apiError = errorGettingBridge || allBridgeFieldsError
  const allDataReady = Boolean(bridgeFormValues && allBridgeFields)

  // every time we receives new bridgeFormValues or new allBridgeFields we create a new fieldMappingMatched state
  const [fieldsMappingMatched, setFieldsMappingMatched] =
    useState<FieldMappingMatched | null>(null)
  useEffect(() => {
    // we also need to be sure that when we have data ready there is not refetching in action or we will generate
    // a new fieldMappingMatched state with non fresh data
    if (
      bridgeFormValues &&
      allBridgeFields &&
      !isReloadingBridge &&
      !isReloadingAllBridgeFields
    ) {
      setFieldsMappingMatched(
        makeFieldsMappingInitialValuesAndListNotMatchingFields(
          bridgeFormValues,
          allBridgeFields
        )
      )
    }
  }, [
    bridgeFormValues,
    allBridgeFields,
    isReloadingBridge,
    isReloadingAllBridgeFields,
  ])

  const bridgeFilterRules = bridgeFormValues?.sourceFilters?.conditions || []

  const initialValues = fieldsMappingMatched?.initialValues
  // we notify users by displaying an alert message if `fieldsMappingMatched` contains not found fields
  useNotifyUserOfMissingMappedFields(fieldsMappingMatched, bridgeId)

  // once bridge is updated we can publish it  we need to setSubmitting(false)
  // not only on error but also when success but result is false.
  // useEffect below will handle the error displaying or the redirection in case of success
  const handlePublishBridge = (
    formikValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => {
    publishBridge(
      { bridgeId: bridgeId },
      {
        onError: (apiError) => {
          formikHelpers.setSubmitting(false)
          const parsedError = parseApiError(apiError)
          trackEvent({
            eventName: 'UnexpectedErrorThrown',
            feature: 'NBEE',
            step: 'Apps',
            params: {
              ...makeNbeeTrackingParams(formikValues),
              custom: {
                errorCode: parsedError.code,
                errorDescription: parsedError.message,
              },
            },
          })
        },
        onSuccess: (data) => {
          if (data.data?.result === false) {
            formikHelpers.setSubmitting(false)
          } else {
            // track event on real publish success
            trackEvent({
              eventName: 'BridgePublished',
              feature: 'NBEE',
              step: 'Publish',
              params: {
                ...makeNbeeTrackingParams(formikValues),
              },
            })
          }
        },
      }
    )
  }

  // when bridge is published we check the status and is 200
  const publishedBridgeReponse = publishBridgeResponse?.data
  const isUserPro = user?.pricing.cbPlanId.split('_')[0] === 'pro'

  useEffect(() => {
    if (publishedBridgeReponse && publishedBridgeReponse.result) {
      // bridge has been published, we can redirect the user
      const firstBridge = publishedBridgeReponse.firstBridge && isUserPro
      const step3State: NbeeStepCompletedState = {
        success: true,
        firstBridge: firstBridge,
      }
      navigate(appRoutes.nbeeStep3.makeUrl(bridgeId.toString()), {
        state: step3State,
      })
    }
  }, [publishedBridgeReponse])

  // if error we notify the user on both update and publish action
  useEffect(() => {
    if (updateBridgeError || publishBridgeApiError) {
      const error = updateBridgeError || publishBridgeApiError
      const errorMessage = parseApiError(error!)

      if (errorMessage.code === 'bridge-limit-reached' && bridgeFormValues) {
        trackEvent({
          eventName: 'UpgradeButtonShown',
          step: 'Apps',
          feature: 'NBEE',
          params: {
            ...makeNbeeTrackingParams(bridgeFormValues),
          },
        })
      }

      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: errorMessage.message,
          useTranslation: true,
          buttons:
            errorMessage.code === 'bridge-limit-reached' ? (
              <a
                href={
                  window.location.origin +
                  appRoutes.pricing.makeUrl() +
                  '?reason=bridge-exceeded'
                }
                target='_blank'
                rel='noreferrer'
              >
                <Button $variant={'primary'}>
                  {t('nbee.checkCompatibility.updatePlanCta')}
                </Button>
              </a>
            ) : undefined,
        })
      )
    }
  }, [updateBridgeError, publishBridgeApiError])

  return apiError ? (
    <NbeeError
      activeStepIndex={1}
      bridgeId={bridgeId}
      errorCode={parseApiError(apiError).code}
      statusCode={parseApiError(apiError).status}
      errorMessage={parseApiError(apiError).message}
    />
  ) : isLoadingAll || !fieldsMappingMatched ? (
    <LoadingStep activeStepIndex={1} bridgeId={bridgeId} />
  ) : allDataReady && initialValues && allBridgeFields ? (
    <FieldsMappingForm
      bridgeId={bridgeId}
      key={`${initialValues.source.appId}-${initialValues.destination.appId}`}
      isSavingApi={isUpdatingBridge || isPublishingBridge}
      initialValues={initialValues}
      allBridgeFields={allBridgeFields.data}
      isFirstBridge={bridgeFormValues?.manualStatus === 1} // checks if it's the first bridge created by this user; in that case we will NOT want to display the edit bar.
      bridgeFilterRulesList={bridgeFilterRules}
      onRefetchBridgeFormValues={onRefetchBridgeFormValues}
      onSubmit={(formValues, formikHelpers) => {
        const updateBridgeData = transformBridgeFormValuesToApiSaveBridgeBody(
          formValues,
          { step: 2 }
        )
        updateBridge(
          {
            bridgeData: updateBridgeData,
            bridgeId: `${bridgeId}`,
          },
          {
            onSuccess: () => {
              handlePublishBridge(formValues, formikHelpers)
            },
            onError: (apiError) => {
              formikHelpers.setSubmitting(false)
              const parsedError = parseApiError(apiError)
              trackEvent({
                eventName: 'UnexpectedErrorThrown',
                feature: 'NBEE',
                step: 'Apps',
                params: {
                  ...makeNbeeTrackingParams(formValues),
                  custom: {
                    errorCode: parsedError.code,
                    errorDescription: parsedError.message,
                  },
                },
              })
            },
          }
        )
      }}
    />
  ) : null
}
